import React, { ComponentPropsWithoutRef } from "react";
import { Button as ButtonBase, CircularProgress } from "@mui/material";
import { cn } from "@lib/cn";

interface ButtonProps extends ComponentPropsWithoutRef<typeof ButtonBase> {
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

function Button({
  children,
  loading,
  disabled,
  className,
  ...props
}: ButtonProps): React.ReactNode {
  return (
    <ButtonBase className={cn("gap-4", className)} {...props} disabled={loading || disabled}>
      {loading ? <CircularProgress color="inherit" size={22} /> : children}
    </ButtonBase>
  );
}

export default Button;
