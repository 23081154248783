"use client";
import { signIn } from "next-auth/react";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import EmailLoginForm from "./email-login-form";
import { withSessionAndRedirectToCallbackURL } from "./with-session-and-redirect-to-callback-url";

function isValidUrl(urlString: string): boolean {
  if (urlString.startsWith("/")) return true;
  try {
    new URL(urlString);
    return true;
  } catch (_error) {
    return false;
  }
}

function Page() {
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl");
  const handleGoogleLogin = async () => {
    signIn("google_via_cognito", {
      callbackUrl: callbackUrl && isValidUrl(callbackUrl) ? callbackUrl : "/dashboard"
    });
  };
  const [selectedEmailLogin, setSelectedEmailLogin] = useState<boolean>(false);
  return (
    <>
      {selectedEmailLogin ? (
        <EmailLoginForm />
      ) : (
        <>
          <div className="flex flex-col gap-4  justify-center m-auto ">
            <div
              onClick={() => handleGoogleLogin()}
              className="flex gap-[14px] px-6 rounded-[8px] py-3 border border-opacity-[0.12] border-black hover:bg-gray-100 cursor-pointer transition-all active:scale-[.98] active:bg-gray-200 select-none"
            >
              <Image alt="Sign in with Google" width={24} height={24} src="/login/google.svg" />{" "}
              Login with Google
            </div>
            <div
              onClick={() => setSelectedEmailLogin(true)}
              className="flex gap-[14px] px-6 rounded-[8px] py-3 border border-opacity-[0.12] border-black hover:bg-gray-100 cursor-pointer transition-all active:scale-[.98] active:bg-gray-200 select-none"
            >
              <Image alt="Sign in with Email" width={24} height={24} src="/login/email.svg" /> Login
              with email or username
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default withSessionAndRedirectToCallbackURL(Page);
