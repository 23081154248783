"use client";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Link from "next/link";
import Button from "@components/ui/button";
import { navigate } from "./actions";
import type { AuthCredentials } from "../../lib/cognitoAuth";

const schema = yup.object().shape({
  username: yup.string().required("Email or username is required"),
  password: yup.string().required("Password is required")
});

const EmailLoginForm = () => {
  const [error, setError] = useState<null | string>(null);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl");

  const onSubmit = async (data: AuthCredentials): Promise<void> => {
    //delay 3 seconds
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await signIn("credentials", {
      username: data.username,
      password: data.password,
      redirect: false
    });
    if (response?.ok) {
      setIsRedirecting(true);
      navigate(response.url || callbackUrl || "/dashboard");
    }
    if (response?.error) {
      if (response?.error === "CredentialsSignin") {
        setError("Invalid credentials, please try again");
      } else {
        setError("Couldn't sign in with provided credentials, please try again");
      }
    }
  };

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form className="max-w-md w-full p-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-2">
        <div>
          <input
            id="username"
            placeholder="Email or username"
            type="text"
            className=" rounded-[4px] border border-gray-300 p-4 w-full h-[56px] text-color-[#000000]/60 "
            {...register("username")}
            name="username"
          />

          <div className="text-red-500 text-xs p-2">
            {formState.errors.username && formState.errors.username.message}
          </div>
        </div>
        <div>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className=" rounded-[4px] border border-gray-300 p-4 w-full h-[56px] text-color-[#000000]/60 "
              {...register("password")}
              name="password"
            />
            <Button
              type="button"
              color="primary"
              className="!absolute !right-0 !top-0 h-full px-3 py-2 hover:bg-transparent"
              onClick={() => setShowPassword(!showPassword)}
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? (
                <RemoveRedEye className="h-4 w-4" />
              ) : (
                <VisibilityOff className="h-4 w-4" />
              )}
            </Button>
          </div>
          <div className="text-red-500 text-xs p-2">
            {formState.errors.password && formState.errors.password.message}
          </div>
        </div>

        {error && <div className="text-red-500 text-xs p-2">{error}</div>}
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-between p-2">
            <Link
              href={"/login/forgot_password"}
              className="text-[#2196F3] active:scale-[.98] transition-all cursor-pointer"
            >
              Forgot password?
            </Link>
          </div>
        </div>

        <Button
          type="submit"
          loading={formState.isSubmitting || isRedirecting}
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: "42px"
          }}
        >
          Sign In
        </Button>
      </div>
    </form>
  );
};

export default EmailLoginForm;
