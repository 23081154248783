"use client";
import { useEffect } from "react";
import { useSession } from "next-auth/react";
import { useRouter, useSearchParams } from "next/navigation";
import { CircularProgress } from "@mui/material";

export function withSessionAndRedirectToCallbackURL(
  Component: React.ComponentType
): React.ComponentType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function WithSessionAndRedirectToCallbackURL(props: any) {
    const { data: session } = useSession();
    const searchParams = useSearchParams();
    const router = useRouter();

    useEffect(() => {
      if (session && !session?.error) {
        const callbackUrl = searchParams.get("callbackUrl");
        router.push(callbackUrl || "/dashboard");
      }
    }, [session, router, searchParams]);

    if (session && !session?.error) {
      return (
        <div className="flex flex-col items-center justify-center w-full">
          <CircularProgress size={100} color="inherit" />
        </div>
      );
    }

    return <Component {...props} />;
  };
}
